
import { Component, Prop } from "vue-property-decorator";
import OtpInput from "./../OtpInput";
import { BaseForm } from "./BaseForm";

@Component({
	components: {
		OtpInput: OtpInput as any,
	},
})
export default class VerifyForm extends BaseForm {
	@Prop({ required: true })
	public readonly principal!: string;

	@Prop({ required: true })
	public readonly state!: string;

	@Prop({ default: "Verify Account" })
	public readonly title!: string;

	@Prop({ default: "Verify" })
	public readonly submitText!: string;

	@Prop()
	public readonly instructions!: string;

	public model = {
		code: "",
	};

	public get hint() {
		if (this.instructions) {
			return this.instructions;
		}
		return `Please enter verification code sent to ${this.principal} in the box provided below`;
	}

	public async send() {
		const response = await this.$api.post(this.action, { ...this.model, principal: this.principal, state: this.state });
		if (!response.ok) {
			return this.handleError(response);
		}
		this.$emit("done", response.body);
	}
}
